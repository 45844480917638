























































































import { Component, Prop, Emit } from 'vue-property-decorator';
import Vue from 'vue';
import { IInputControlDocument } from '@store/modules/input-control/Interfaces';
import '../scss/InputControlRow.scss';

@Component({
    name: 'InputControlRow',
})

export default class InputControlRow extends Vue {
    @Prop({}) item!: IInputControlDocument;
    @Prop({}) isOnTop!: boolean;
    @Prop({ default: false }) hideQualityDocuments!: boolean;
    @Prop({ default: 0 }) countTasks!: number;

    @Emit('show-task-list')
    emitShowTaskList(): IInputControlDocument {
        return this.item;
    }

    @Emit('show-popup-create-task')
    emitShowPopupCreateTask(): IInputControlDocument {
        return this.item;
    }

    emitClick() {
        this.$emit('open-docs-popup', this.item.documentsQuality);
    }
}
